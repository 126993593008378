/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Head from "next/head"
import PropTypes from "prop-types"
import { isBrowser } from "settings"
import { CircularProgress, Grid, Box, Typography, useTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const Layout = ({ children }) => {
  // const theme = useTheme()
  // const isMobile = useMediaQuery("(max-width:720px)", { defaultMatches: true })

  return (
    <>
      <Head>
        {/* <title>{data.title}</title> */}
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
      </Head>

      {!isBrowser ? (
        <CircularProgress />
      ) : (
        <Grid>
          <>
            {children}

            {/* DESKTOP VERSION */}
            {/* MOBILE VERSION */}
            {/*
            {!isMobile && (
              <Grid>
                <Box
                  display="flex"
                  flex="1"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    height: "100vh",
                    backgroundColor: theme?.palette.primary.main,
                    position: "fixed",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    zIndex: "1000",
                  }}
                >
                  <Box>
                    <img src="/images/badtraveller_logo_v3.png" alt="Badtraveller" style={{ maxWidth: 260 }} />
                    <br />
                    <Typography variant="h2" style={{ marginTop: 20, color: "white" }}>
                      Il primo portale dedicato
                      <br /> alla disabilità in viaggio
                    </Typography>
                    <img
                      style={{ marginTop: 20, marginBottom: 20 }}
                      src="/images/badtraveller.gif"
                      alt="Badtraveller - Il portale di riferimento per ..."
                      width={100}
                    />
                    <Typography variant="h4" style={{ marginTop: 20, color: "white" }}>
                      Per poter usufruire del servizio di Badtraveller,
                      <br />
                      usare un dispositivo mobile.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              )}
              */}
          </>
        </Grid>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
